import footerHTML from "../footer.html";
import '../styles/footer.scss';

window.MoFooter  = class MoFooter {
    constructor({renderTo = document.body} = {}) {
        this.renderTo = renderTo;
        this.footerHTML = footerHTML;

        this.render();
    }

    render() {
        let footerNode = document.createElement('footer');
        let currentYear = new Date().getFullYear();

        footerNode.innerHTML = this.footerHTML;
        this.renderTo.appendChild(footerNode);
        document.getElementById("currentYear").innerHTML = currentYear;
    }
};
